import * as React from 'react';
import MUIDataTable from 'mui-datatables';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { fetchCurrentUsers, getUsers } from '../../../redux/actions/users';
import ListItem from '@mui/material/ListItem';
import { Colors, UserStatus } from '../../../enums/enums';
import ListItemText from '@mui/material/ListItemText';
import EditUserDialog from './EditUserDialog';
import { useState } from 'react';
import { IUser } from '../../../interfaces/interfaces';
import DoneIcon from '@mui/icons-material/Done';
import { fetchCurrentClients, getClients } from '../../../redux/actions/clients';

const Users: React.FC = () => {
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const clients = useSelector(getClients);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [user, setUser] = useState(null);

  React.useEffect(() => {
    dispatch(fetchCurrentUsers());
    dispatch(fetchCurrentClients());
  }, [dispatch]);

  const getUser = (userId: string): IUser => {
    const foundUser: IUser = users?.find((user) => user?.id === userId);
    console.log('FOUND USER', foundUser);
    return foundUser;
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: false
      }
    },
    {
      name: 'authId',
      label: 'Portal Account? ',
      options: {
        customBodyRender: (v, x) => {
          return v ? (
            <Typography sx={{ color: 'green', width: '40px', mr: -4 }}>
              <CheckCircleRoundedIcon fontSize="large" />
            </Typography>
          ) : (
            <Typography sx={{ color: 'darksalmon', width: '40px' }}>
              <CancelRounded fontSize="large" />
            </Typography>
          );
        }
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        sort: true,
        customBodyRender: (v, x) => (
          <>
            <ListItem sx={{ ml: -8 }}>
              <ListItemText
                sx={{ ml: -2, minWidth: '250px' }}
                primary={
                  <React.Fragment>
                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: -3, mb: -1 }}>
                      {/*view profile*/}
                      <Tooltip placement="top" arrow title="View Profile">
                        <a href={`/users/${x?.rowData[0]}`} target="_blank" rel="noopener noreferrer">
                          <SearchRoundedIcon style={{ color: 'grey' }} />
                        </a>
                      </Tooltip>
                      {/*edit profile*/}
                      <Tooltip placement="top" arrow title="Edit User">
                        <EditIcon
                          sx={{ cursor: 'pointer', color: 'grey', ml: 1 }}
                          onClick={() => {
                            const foundUser = getUser(x?.rowData[0]);
                            setUser(foundUser);
                            setOpenEditUserDialog(true);
                          }}
                        />
                      </Tooltip>
                    </Box>

                    <Typography sx={{ color: Colors.MenuText, fontSize: '1.07rem' }}>
                      <b>{v}</b>
                    </Typography>
                    <Typography style={{ fontSize: 'small' }}>Age: {x?.rowData[15]}</Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                      {x.rowData[9]} at{' '}
                      <span style={{ color: Colors.TextElevated, fontWeight: 'bold' }}>{x?.rowData[10]}</span>
                    </Typography>
                    {x?.rowData[11] && <Typography variant="body2">Experience: {x?.rowData[11]} years</Typography>}
                    <Typography variant="body2">
                      Leadership Experience: {x?.rowData[19] ? x?.rowData[19] : '---'}
                    </Typography>
                    <Typography variant="body2">
                      Client: <b>{clients?.find((client: { id: string }) => client.id === x?.rowData[18])?.name}</b>
                    </Typography>
                    {x?.rowData[16] && (
                      <Chip
                        color="success"
                        icon={<DoneIcon color="success" />}
                        label="ICF Certified Coach"
                        sx={{ fontSize: '0.9rem', fontWeight: 'bold', mt: 1 }}
                        variant="outlined"
                      />
                    )}

                    {x?.rowData[17] && (
                      <Chip
                        icon={<DoneIcon color="success" />}
                        label="Facilitator"
                        sx={{ fontSize: '0.9rem', fontWeight: 'bold', color: 'green', mt: 1 }}
                        variant="outlined"
                      />
                    )}
                  </React.Fragment>
                }
              />
            </ListItem>
          </>
        )
      }
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        customBodyRender: (v, x) => (
          <>
            <Typography sx={{ mr: -2 }}>{v}</Typography>
            {x?.rowData[4] && (
              <Typography sx={{ mr: -1 }} fontSize="small">
                <span style={{ color: 'grey' }}>Alternate email</span>: {x?.rowData[12]}
              </Typography>
            )}
          </>
        )
      }
    },
    {
      name: 'linkedin',
      label: 'LinkedIn Profile',
      options: {
        customBodyRender: (v, x) => (
          <>
            {v !== 'N/A' && v?.includes('linkedin.com') ? (
              <Box sx={{ maxWidth: '400px', width: '330px', wordWrap: 'break-word' }}>
                <a href={v} target="_blank" rel="noreferrer nofollow">
                  {v}
                </a>
              </Box>
            ) : (
              <Typography sx={{ maxWidth: '400px', width: '330px', wordWrap: 'break-word' }} fontSize="small">
                {v}
              </Typography>
            )}
          </>
        )
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        customBodyRender: (v, x) => (
          <Chip
            style={{ color: 'black', backgroundColor: v === UserStatus.Active ? Colors.Active : Colors.Inactive }}
            label={v}
          />
        )
      }
    },
    {
      name: 'note',
      label: 'Note',
      options: {
        customBodyRender: (v, x) => (
          <Typography fontSize="small" sx={{ width: '150px' }}>
            {v}
          </Typography>
        )
      }
    },
    {
      name: 'city',
      label: 'Location',
      options: {
        customBodyRender: (v, x) => {
          return (
            <>
              <Typography fontSize="small">{v},</Typography>
              <Typography fontSize="small">{x?.rowData[14]}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'timezone',
      label: 'Timezone',
      options: {
        customBodyRender: (v, x) => {
          return (
            <>
              <Typography fontSize="small">{v}</Typography>
            </>
          );
        }
      }
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        display: false
      }
    },
    {
      name: 'company',
      label: 'Company',
      options: {
        display: false
      }
    },
    {
      name: 'experience',
      label: 'Experience',
      options: {
        display: false
      }
    },
    {
      name: 'email2',
      label: 'Alternate Email',
      options: {
        display: false
      }
    },
    {
      name: 'linkedin',
      label: 'LinkedIn',
      options: {
        display: false
      }
    },
    {
      name: 'country',
      label: 'Country',
      options: {
        display: false
      }
    },
    {
      name: 'age',
      label: 'Age',
      options: {
        display: false
      }
    },
    {
      name: 'isCertifiedCoach',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'isFacilitator',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'clientId',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'leadershipExperience',
      label: '',
      options: {
        display: false
      }
    },
    {
      name: 'id',
      label: 'View Profile',
      options: {
        customBodyRender: (v) => (
          <a href={`/users/${v}`} target="_blank" rel="noopener noreferrer">
            <SearchRoundedIcon style={{ fontSize: '1.8rem', color: Colors.ButtonGreen }} />
          </a>
        )
      }
    },
    {
      name: 'id',
      label: 'Edit User',
      options: {
        customBodyRender: (v) => {
          return (
            <EditIcon
              sx={{ cursor: 'pointer', fontSize: '1.8rem', color: Colors.MenuText }}
              onClick={() => {
                const foundUser = getUser(v);
                setUser(foundUser);
                setOpenEditUserDialog(true);
              }}
            />
          );
        }
      }
    }
  ];

  const options = {
    filter: true,
    filterType: 'columnHeaderDragOver',
    selectableRows: false
  };

  return (
    <div style={{ paddingLeft: '8px' }}>
      <Typography sx={{ mb: 3, mt: 3, ml: 4, color: Colors.TextElevated, fontSize: '1.6rem', fontWeight: 'bold' }}>
        Users
      </Typography>
      <MUIDataTable data={users} columns={columns} options={options} />
      <EditUserDialog open={openEditUserDialog} user={user} onClose={() => setOpenEditUserDialog(false)} />
    </div>
  );
};

export default Users;