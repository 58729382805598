import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Select,
  Stack,
  Box,
  Autocomplete,
  MenuItem,
  DialogContent,
  FormControlLabel,
  Switch,
  FormGroup,
  Dialog,
  DialogActions,
  DialogTitle
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { IUser } from '../../../interfaces/interfaces';
import { updateUser } from '../../../redux/actions/users';
import { Colors, UserStatus } from '../../../enums/enums';
import { Controller, useForm } from 'react-hook-form';
import { TIMEZONES } from '../../../constants/constants';

interface Props {
  open: boolean;
  user: IUser;
  onClose: Dispatch<SetStateAction<boolean>>;
}

interface IUpdateUser {
  id: string;
  name: string;
  status: string;
  email: string;
  email2: string;
  company: string;
  title: string;
  experience: string;
  leadershipExperience: string;
  linkedin: string;
  note: string;
  country: string;
  city: string;
  timezone: string;
  isCertifiedCoach: boolean;
  isFacilitator: boolean;
}

const EditUserDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { open, user, onClose } = props;
  const [coachFlag, setCoachFlag] = useState(user?.isCertifiedCoach);
  const [facilitatorFlag, setFacilitatorFlag] = useState(user?.isFacilitator);

  useEffect(() => {
    if (user) {
      setCoachFlag(user?.isCertifiedCoach);
      setFacilitatorFlag(user?.isFacilitator);
    }
  }, [user]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: user?.name,
      status: user?.status,
      linkedin: user?.linkedin,
      email: user?.email,
      email2: user?.email2,
      company: user?.company,
      title: user?.title,
      experience: user?.experience,
      leadershipExperience: user?.leadershipExperience,
      note: user?.note,
      country: user?.country,
      city: user?.city,
      timezone: user?.timezone,
      isCertifiedCoach: user?.isCertifiedCoach,
      isFacilitator: user?.isFacilitator
    }
  });

  const onSubmit = (data: IUpdateUser) => {
    const {
      name,
      status,
      email,
      email2,
      company,
      experience,
      leadershipExperience,
      title,
      linkedin,
      note,
      country,
      city,
      timezone
    } = data;
    console.log('Coachflag:', coachFlag);
    console.log('facilitator flag:', facilitatorFlag);
    try {
      dispatch(
        updateUser(
          user.id,
          name,
          status,
          email,
          email2,
          company,
          title,
          experience,
          leadershipExperience,
          linkedin,
          note,
          country,
          city,
          timezone,
          coachFlag,
          facilitatorFlag
        )
      );
      handleClose();
    } catch (e) {
      console.error('Error updating user: ', e);
    }
  };

  const trimInput = (event, fieldName): void => {
    const trimmedValue = event?.target?.value?.trim();
    setValue(fieldName, trimmedValue);
  };

  const handleClose = () => {
    onClose(!open);
    reset();
  };

  return (
    <Dialog sx={{ height: '95%vh' }} open={open} onClose={handleClose} maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle style={{ color: Colors.TextElevated, backgroundColor: Colors.BackgroundDrawer }}>
          Update User
        </DialogTitle>
        <DialogContent style={{ width: '700px', backgroundColor: Colors.BackgroundMain }}>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ width: '100%' }}
                    label="Name"
                    color="success"
                    placeholder="enter name"
                    defaultValue={user?.name}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'name')}
                  />
                )}
                name="name"
                control={control}
              />

              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    variant="outlined"
                    defaultValue={user?.status}
                    onChange={(e) => setValue('status', e.target.value)}
                  >
                    <MenuItem value={UserStatus.Active}>{UserStatus.Active}</MenuItem>
                    <MenuItem value={UserStatus.Inactive}>{UserStatus.Inactive}</MenuItem>
                  </Select>
                )}
                name="status"
                control={control}
              />
            </Stack>

            <FormGroup>
              <Stack direction="row" spacing={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={coachFlag}
                      onChange={() => {
                        setCoachFlag(!coachFlag);
                        setFacilitatorFlag(false);
                      }}
                      name="isCertifiedCoach"
                    />
                  }
                  label="ICF Certified Coach"
                />

                <FormControlLabel
                  control={
                    <Switch
                      color="success"
                      checked={facilitatorFlag}
                      onChange={() => {
                        setFacilitatorFlag(!facilitatorFlag);
                        setCoachFlag(false);
                      }}
                      name="isFacilitator"
                    />
                  }
                  label="Facilitator"
                />
              </Stack>
            </FormGroup>

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Primary Email"
                  color="success"
                  defaultValue={user?.email}
                  placeholder="enter primary email"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="email"
                  variant="outlined"
                  onBlur={(event) => trimInput(event, 'email')}
                />
              )}
              name="email"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Alternate Email"
                  color="success"
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="email"
                  defaultValue={user?.email2}
                  placeholder="enter alternate email"
                  variant="outlined"
                  onBlur={(event) => trimInput(event, 'email2')}
                />
              )}
              name="email2"
              control={control}
            />

            <Stack direction="row" spacing={2}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Company"
                    color="success"
                    sx={{ width: '90%' }}
                    defaultValue={user?.company}
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="enter company name"
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'company')}
                  />
                )}
                name="company"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="experience"
                    options={['0-5', '6-10', '11-15', '16-20', '21+']}
                    sx={{ width: '50%' }}
                    autoHighlight
                    defaultValue={user?.experience}
                    getOptionLabel={(option) => option + ' years '}
                    onChange={(event, newInputValue) => {
                      field.onChange(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option} years
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Experience"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        color="success"
                      />
                    )}
                  />
                )}
                name="experience"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="leadershipExperience"
                    options={['None (0 years)', '1-3 years', '4-5 years', '6-10 years', 'More than 10 years']}
                    sx={{ width: '50%' }}
                    autoHighlight
                    defaultValue={user?.leadershipExperience}
                    getOptionLabel={(option) => option}
                    onChange={(event, newInputValue) => {
                      field.onChange(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Leadership Experience"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        color="success"
                      />
                    )}
                  />
                )}
                name="leadershipExperience"
                control={control}
              />
            </Stack>

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Job Title"
                  color="success"
                  defaultValue={user?.title}
                  InputLabelProps={{
                    shrink: true
                  }}
                  placeholder="enter job title"
                  variant="outlined"
                  onBlur={(event) => trimInput(event, 'title')}
                />
              )}
              name="title"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="LinkedIn Profile"
                  color="success"
                  defaultValue={user?.linkedin}
                  helperText="copy and paste the entire profile link including https://"
                  placeholder="i.e. https://www.linkedin.com/in/user-profile/"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  onBlur={(event) => trimInput(event, 'linkedin')}
                />
              )}
              name="linkedin"
              control={control}
            />

            <Controller
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Note"
                  color="success"
                  defaultValue={user?.note}
                  placeholder="enter note"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  onBlur={(event) => trimInput(event, 'note')}
                />
              )}
              name="note"
              control={control}
            />
            <Stack direction="row" spacing={2}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Country"
                    color="success"
                    sx={{ width: '80%' }}
                    defaultValue={user?.country}
                    placeholder="enter country"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'country')}
                  />
                )}
                name="country"
                control={control}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="City"
                    color="success"
                    sx={{ width: '80%' }}
                    defaultValue={user?.city}
                    placeholder="enter city"
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    onBlur={(event) => trimInput(event, 'city')}
                  />
                )}
                name="city"
                control={control}
              />

              <Controller
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="timezone"
                    sx={{ width: '100%' }}
                    options={TIMEZONES}
                    autoHighlight
                    defaultValue={user?.timezone}
                    getOptionLabel={(option) => option}
                    onChange={(event, newInputValue) => {
                      field.onChange(newInputValue);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Timezone"
                        InputLabelProps={{
                          shrink: true
                        }}
                        variant="outlined"
                        color="success"
                      />
                    )}
                  />
                )}
                name="timezone"
                control={control}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions style={{ backgroundColor: Colors.BackgroundDrawer }}>
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'grey', mr: 2, mb: 2 }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              sx={{ backgroundColor: Colors.ButtonGreen, mr: 2, mb: 2 }}
              type="submit"
            >
              Update User
            </Button>
          </>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditUserDialog;