import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import DoneIcon from '@mui/icons-material/Done';
import {
  Box,
  Avatar,
  Typography,
  CardContent,
  Card,
  CardHeader,
  Grid,
  Button,
  Stack,
  TextField,
  Chip,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';
import { approveNewUser, fetchUserById, getUser } from '../../../redux/actions/users';
import { Colors, SurveyType, UserStatus } from '../../../enums/enums';
import { fetchSurveysResponsesByUserId, getSurveysResponseByUserId } from '../../../redux/actions/surveysResponse';
import { useHistory, useParams } from 'react-router-dom';
import { IFetchedUser, IUser } from '../../../interfaces/interfaces';
import { format, parseISO } from 'date-fns';
import InfoIcon from '@mui/icons-material/Info';
import { ConfirmationDialog } from '../../dialogs/ConfirmationDialog';
import { useState } from 'react';
import { toast } from 'react-toastify';
import EditUserDialog from './EditUserDialog';
import UserCohortsAndSessions from './UserCohortsAndSessions';
import DeleteUserDialog from './DeleteUserDialog';
import DeclineUserDialog from './DeclineUserDialog';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

interface Props {}

const UserProfile = (props: Props) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [note, setNote] = useState(null);
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const [openDeclineUserDialog, setOpenDeclineUserDialog] = useState(false);
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState(null);

  const fetchedUser: IFetchedUser = useSelector(getUser);
  const user: IUser = fetchedUser?.user;
  const clientName: string = fetchedUser?.clientName;
  const portalUrl =
    process.env.NODE_ENV === 'development' || window?.location.href.split('.')?.includes('stg')
      ? 'https://portal.stg.locelle.com'
      : 'https://portal.locelle.com';

  React.useEffect((): void => {
    dispatch(fetchUserById(id));
    dispatch(fetchSurveysResponsesByUserId(id));
  }, [dispatch, id]);

  const approveUser = (): void => {
    try {
      if (note) {
        dispatch(approveNewUser(user?.id, portalUrl, note));
      } else {
        dispatch(approveNewUser(user?.id, portalUrl));
      }

      setConfirmationDialogOpen(false);
    } catch (error) {
      toast.error('Error approving new User registration', error);
      setConfirmationDialogOpen(false);
    }
    history.push('/');
  };
  console.log('USER => ', user);

  const surveysResponse = useSelector((state) => user && getSurveysResponseByUserId(state, user?.id));

  const surveyResponse =
    surveysResponse &&
    surveysResponse
      .filter(
        (s) =>
          SurveyType.Onboarding === s?.survey?.type ||
          SurveyType.OnboardingMentor === s?.survey?.type ||
          SurveyType.OnboardingMentee === s?.survey?.type
      )
      .pop();
  console.log('SURVEY RESPONSE', surveyResponse);

  return (
    <>
      <Box>
        <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
          <CardHeader
            style={{ backgroundColor: Colors.TextElevated, color: 'white' }}
            avatar={<Avatar sx={{ bgcolor: Colors.ButtonGreen }}>{user?.name?.charAt(0)}</Avatar>}
            title={
              <Typography gutterBottom variant="h5" component="h2" sx={{ ml: 1 }}>
                {user?.name}
                <a href={user?.linkedin} target="_blank" rel="noreferrer">
                  <LinkedInIcon style={{ color: 'white', marginLeft: '5px' }} />
                </a>
              </Typography>
            }
            subheader={
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ color: '#FFFFFF' }}>
                  <LocationOnIcon style={{ float: 'left', marginLeft: '5px' }} />
                  <Typography style={{ float: 'left' }}>
                    {user?.city}, {user?.country}
                  </Typography>
                </Typography>
                {user?.note && (
                  <Stack direction="row" spacing={1} sx={{ mr: 2 }}>
                    <InfoIcon sx={{ color: 'white' }} />
                    <Typography sx={{ color: 'white', maxWidth: '400px', mr: 3 }}>Note: {user?.note}</Typography>
                  </Stack>
                )}
              </Stack>
            }
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CardContent sx={{ ml: 2, color: Colors.Text }}>
                <Typography variant="h6" gutterBottom>
                  CLIENT: <span style={{ color: Colors.TextElevated }}>{clientName?.toUpperCase()}</span>
                </Typography>

                {user?.isCertifiedCoach && (
                  <Chip
                    color="success"
                    icon={<DoneIcon color="success" />}
                    label="ICF Certified Coach"
                    sx={{ fontSize: '1rem', fontWeight: 'bold', my: 2 }}
                    variant="outlined"
                  />
                )}

                {user?.isFacilitator && (
                  <Chip
                    icon={<DoneIcon color="success" />}
                    label="Facilitator"
                    sx={{ fontSize: '1rem', fontWeight: 'bold', my: 2, color: 'green' }}
                    variant="outlined"
                  />
                )}
                <Typography sx={{ mb: 1, mt: 2, color: Colors.Text }}>
                  <b>Timezone: </b> {user?.timezone}
                </Typography>
                <Typography sx={{ mb: 1, color: Colors.Text }}>
                  <b>Onboard: </b> {user ? format(parseISO(user?.createdAt), 'MMM dd, yyyy - h:mm aa') : ''}
                </Typography>
                <Typography sx={{ mb: 1, color: Colors.Text }}>
                  <b>Primary Email: </b> {user?.email}
                </Typography>
                <Typography sx={{ color: Colors.Text }}>
                  <b>Alternate Email: </b> {user?.email2}
                </Typography>
              </CardContent>
              <CardContent sx={{ ml: 2 }}>
                <Typography sx={{ mb: 1, color: Colors.Text }}>
                  <b>Age</b>: {user?.age !== null ? `${user?.age} years` : 'Prefer not to say'}
                </Typography>
                <Typography sx={{ mb: 1, color: Colors.Text }}>
                  <b>Job Title</b>: {user?.title} at{' '}
                  <span style={{ color: Colors.TextElevated, fontWeight: 'bold' }}>{user?.company}</span>
                </Typography>
                <Typography sx={{ mb: 1, color: Colors.Text }}>
                  <b>Experience</b>: {user?.experience} years
                </Typography>
                <Typography sx={{ color: Colors.Text }}>
                  <b>Leadership Experience</b>: {user?.leadershipExperience}
                </Typography>
              </CardContent>
              <CardContent sx={{ ml: 2, color: Colors.Text }}>
                {surveyResponse?.response?.learningStyle && (
                  <Typography sx={{ mb: 2, color: Colors.Text }}>
                    <b>Learning Style: </b>
                    <br />
                    {surveyResponse?.response?.learningStyle}
                  </Typography>
                )}
                {surveyResponse?.response?.accessibility && (
                  <Typography sx={{ mb: 2, color: Colors.Text }}>
                    <b>Accessibility Needs: </b>
                    <br />
                    {surveyResponse?.response?.accessibility}
                  </Typography>
                )}
                {user?.careerGoals && (
                  <Typography sx={{ mb: 1, color: Colors.Text }}>
                    <b>Career Goals: </b>
                  </Typography>
                )}
                <Box sx={{ ml: 1 }}>
                  <Stepper orientation="vertical" activeStep={-1}>
                    {user?.careerGoals
                      ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
                      ?.map((careerGoal, index) => (
                        <Step key={careerGoal.date} completed={false} active={true}>
                          <StepLabel
                            StepIconComponent={(props) => (
                              <AssignmentTurnedInIcon {...props} sx={{ color: Colors.ButtonGreen }} />
                            )}
                          >
                            <Typography sx={{ color: Colors.TextElevated, mr: 0.5 }}>
                              {new Date(careerGoal.date)?.toLocaleDateString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                              })}
                            </Typography>
                          </StepLabel>
                          <StepContent>
                            <Typography>{careerGoal.goal}</Typography>
                          </StepContent>
                        </Step>
                      ))}
                  </Stepper>
                </Box>
              </CardContent>
            </Grid>

            {UserStatus.New === user?.status && (
              <Grid item xs={6}>
                <Card elevation={6} sx={{ m: 4 }}>
                  <CardContent sx={{ p: 3 }}>
                    <Typography sx={{ fontSize: '1.2rem', mb: 3, color: Colors.Text }}>
                      {user?.name?.split(' ')[0]} is waiting for registration approval
                    </Typography>
                    <TextField
                      label="Add a profile note (optional)"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true
                      }}
                      color="success"
                      onChange={(event) => {
                        setNote(event?.target?.value);
                      }}
                    />
                  </CardContent>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ mt: 1, mr: 3, backgroundColor: Colors.ButtonGreen, float: 'right' }}
                    onClick={() => setConfirmationDialogOpen(true)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ mt: 1, mr: 3, mb: 2, float: 'right' }}
                    onClick={() => {
                      setUserProfile(user);
                      setOpenDeclineUserDialog(true);
                    }}
                  >
                    Decline
                  </Button>
                </Card>
              </Grid>
            )}
            {UserStatus.New !== user?.status && (
              <Grid item xs={6}>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
                  <UserCohortsAndSessions user={user} />

                  <Stack direction="column" spacing={3} sx={{ mx: 3 }}>
                    <Button
                      sx={{ backgroundColor: Colors.ButtonGreen, width: '180px', height: '40px' }}
                      variant="contained"
                      color="success"
                      onClick={() => {
                        setUserProfile(user);
                        setOpenEditUserDialog(true);
                      }}
                    >
                      Update User
                    </Button>

                    {/* uncomment when BE endpoint is completed*/}
                    {/*                    <Button*/}
                    {/*                      sx={{ width: '180px', height: '40px' }}*/}
                    {/*                      variant="outlined"*/}
                    {/*                      color="error"*/}
                    {/*                      onClick={() => {*/}
                    {/*                        setUserProfile(user);*/}
                    {/*                        setOpenDeleteUserDialog(true);*/}
                    {/*                      }}*/}
                    {/*                    >*/}
                    {/*                      Delete User*/}
                    {/*                    </Button>*/}
                  </Stack>
                </Box>
              </Grid>
            )}
          </Grid>
        </Card>
      </Box>
      <ConfirmationDialog
        isOpen={confirmationDialogOpen}
        title="Registration Approval"
        confirmBtnText="Approve"
        message={
          <>
            <Typography gutterBottom>
              Are you sure you want to approve {user?.name?.split(' ')[0]} as a new user?
            </Typography>
            <Typography>They will receive a welcome email with instructions to create a new Portal account.</Typography>
          </>
        }
        onConfirmBtn={approveUser}
        onCancelBtn={() => setConfirmationDialogOpen(false)}
      />
      <EditUserDialog
        open={openEditUserDialog}
        user={userProfile}
        onClose={() => {
          setOpenEditUserDialog(false);
          dispatch(fetchUserById(id));
        }}
      />
      <DeleteUserDialog open={openDeleteUserDialog} user={userProfile} onClose={() => setOpenDeleteUserDialog(false)} />
      <DeclineUserDialog
        open={openDeclineUserDialog}
        user={userProfile}
        onClose={() => setOpenDeclineUserDialog(false)}
      />
    </>
  );
};

export default UserProfile;