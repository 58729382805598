import api from '../../services/api';
import { toast } from 'react-toastify';
import { UserStatus } from '../../enums/enums';

export enum UserActionTypes {
  FETCH = 'USERS_FETCH',
  FETCH_USER_BY_ID = 'FETCH_USER_BY_ID',
  USER_UPDATE = 'USER_UPDATE',
  DELETE = 'DELETE_USER',
  ERROR = 'USER_ERROR'
}

export function fetchCurrentUsers() {
  return function (dispatch) {
    return api.get('/users/all').then(({ data }) => {
      dispatch({
        type: UserActionTypes.FETCH,
        payload: data
      });
    });
  };
}

export function fetchUserById(userId) {
  return function (dispatch) {
    return api.get(`/users/${userId}`).then(({ data }) => {
      dispatch({
        type: UserActionTypes.FETCH_USER_BY_ID,
        payload: data
      });
    });
  };
}

export function updateUser(
  userId: string,
  name: string,
  status: string,
  email: string,
  email2: string,
  company: string,
  title: string,
  experience: string,
  leadershipExperience: string,
  linkedin: string,
  note: string,
  country: string,
  city: string,
  timezone: string,
  coachFlag: boolean,
  facilitatorFlag: boolean
) {
  return function (dispatch) {
    return api
      .patch('/users/profile', {
        userId,
        name,
        status,
        email,
        email2,
        company,
        title,
        experience,
        leadershipExperience,
        linkedin,
        note,
        country,
        city,
        timezone,
        coachFlag,
        facilitatorFlag
      })
      .then(({ data }) => {
        toast.success('Successfully updated user');
        dispatch({
          type: UserActionTypes.USER_UPDATE,
          payload: data
        });
      })
      .catch((error) => {
        toast.error('Error updating user', error?.message);
      });
  };
}

export function approveNewUser(userId: string, portalUrl: string, note?: string) {
  return function (dispatch) {
    return api
      .patch(`users//approve-registration/${userId}`, { portalUrl, note })
      .then(({ data }) => {
        toast.success('Successfully approved new User');
      })
      .catch((error) => {
        toast.error(`Error approving new User: ${error?.response?.data?.message}`);
      });
  };
}

export function deleteUser(userId: string) {
  return function (dispatch) {
    const url = `users/${userId}`;
    return api
      .delete(url)
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;

        if (1 === data) {
          dispatch({
            type: UserActionTypes.DELETE,
            payload: userId
          });
          toast.success('Successfully deleted user');
        } else {
          toast.error(`Error deleting user.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error deleting user', error);
        toast.error(
          `Error deleting user.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}
export function declineUser(userId: string) {
  return function (dispatch) {
    const url = `users/decline/${userId}`;
    return api
      .delete(url)
      .then((deleteResponse) => {
        const { data, statusText } = deleteResponse;

        if (1 === data) {
          dispatch({
            type: UserActionTypes.DELETE,
            payload: userId
          });
          toast.success('Successfully declined user registration');
        } else {
          toast.error(`Error declining user.\nMessage: ${statusText}`);
        }
      })
      .catch((error) => {
        console.error('Error declining user', error);
        toast.error(
          `Error declining user.\nMessage: ${error.response?.data?.message || error.request || error.message}`
        );
      });
  };
}

// selectors
export const getUsers = (state) => state.users.users;
export const getActiveUsers = (state) => state.users.users?.filter((u) => u.status === UserStatus.Active);
export const getUser = (state) => state.users.user;
export const getUserError = (state, userId) => state.users.errors.find((user) => user.id === userId);